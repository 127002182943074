<template>
  <div class="detail">
    <div class="title">{{info.header}}</div>
    <div class="text" v-html="info.text"></div>
  </div>
</template>

<script>
import { getAfficheDetail } from '@/api/team-info'
export default {
  name: 'fanhan-detail',
  data () {
    return {
      id: '',
      info: {}
    }
  },
  created () {
    this.id = this.$route.query.id
    this.init()
  },
  methods: {
    init () {
      getAfficheDetail({ aid: this.id }).then(res => {
        if (res.code === 0) {
          this.info = res.data
        } else {
          this.$notify.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail{
  padding: 50px 100px;
  // min-width: 1272px;
  .title{
    margin-bottom: 51px;
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    text-align: center;
  }
}
</style>
